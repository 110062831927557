// @flow
import React from 'react';
import ReactSelect from 'react-select';
import _ from 'lodash';
import { change as rrChange } from 'redux-form';
import { connect } from 'react-redux';

import css from './ReactSelect.scss';

type Props = {
	placeholder?: string,
	options: Array<any>,
	input: ReduxFormInput,
	name: string,
};
function Select(props: Props) {
	const { placeholder, name, input, options } = props;
	const customStyles = {
		option: (provided, { isSelected }) => ({
			...provided,
			background: isSelected ? 'rgb(214, 214, 214)' : '#fff',
			color: 'rgb(95, 95, 95)',
		}),

		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';

			return { ...provided, opacity, transition };
		},
	};

	return (
		<ReactSelect
			name={name}
			onChange={(e: any) => {
				_.invoke(props, 'input.onChange', e);
			}}
			// onBlur={(e: any) => _.invoke(props, 'input.onBlur', e)}
			options={options}
			value={input.value}
			placeholder={placeholder}
			styles={customStyles}
			classNamePrefix="react-select"
			className={css.selectC}
		/>
	);
}

Select.defaultProps = {
	placeholder: '',
};

const mapState = (state, ownProps) => {
	return {};
};
const mapDispatch = dispatch => ({
	// uploadMedia: file => dispatch(actions.uploadMedia(file)),
	changeField: (formName, fieldName, value) => dispatch(rrChange(formName, fieldName, value)),
});

export default connect(mapState, mapDispatch)(Select);
