// @flow
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import {
	GoogleAuthProvider,
	FacebookAuthProvider,
	signInWithRedirect,
	signInWithPopup,
	signInWithCustomToken,
} from 'firebase/auth';

import { firebaseAuth } from 'common/firebase';
import { getProviderName } from 'common/utils';
import { CHECK_AUTH_STATE, LOGIN, SET_AUTH_ERROR, SET_AUTH_BUSY_STATE, LOGOUT } from 'client/constants/actions';

import * as api from 'api';
import ApiWorker from 'api/ApiWorker';

import * as responsiveCss from 'client/components/common/Responsive/Responsive.scss';

type LoginParams = {
	onBeforeComplete?: Function,
	type?: 'facebook' | 'google' | 'anonymous',
};

function parseUserData(user) {
	const isAnonymouse = user.uid.includes('anonymous');
	const providerId = _.get(user, 'providerData.0.providerId');
	const provider = getProviderName(providerId);

	let photoURL = user.photoURL || '';
	if (provider === 'facebook' && photoURL) {
		photoURL = `${photoURL}?width=200&height=200`;
	}

	return {
		name: user.displayName || user.email || user.phoneNumber || (isAnonymouse ? 'Anonymous' : ''),
		phoneNumber: user.phoneNumber || '',
		email: user.email || '',
		picture: photoURL,
		uid: user.uid,
		loginMethod: provider,
	};
}

const MOBILE = responsiveCss.mobile;

function isMatching(query) {
	return window && !!window.matchMedia(query).matches;
}

export function checkAuthState(params: ?LoginParams) {
	const { onBeforeComplete } = params || {};

	return async (dispatch: Dispatch) => {
		try {
			dispatch({ type: CHECK_AUTH_STATE.START });

			const result = await new Promise(resolve => firebaseAuth.onAuthStateChanged(user => resolve(!!user)));

			if (result) {
				const user = firebaseAuth.currentUser;
				ApiWorker.token = await user.getIdToken(true);

				const userData = await api.authUser();

				if (userData.success && userData.data) {
					if (onBeforeComplete) {
						await onBeforeComplete();
					}

					dispatch<UserData>({
						type: CHECK_AUTH_STATE.SUCCESS,
						payload: {
							...parseUserData(user),
							...userData.data,
						},
					});
					return user;
				}
				throw new Error(userData.error);
			}

			dispatch(logout());
			dispatch({ type: CHECK_AUTH_STATE.FAIL, payload: '' });

			return null;
		} catch (e) {
			const msg: string = e.message;

			dispatch({ type: CHECK_AUTH_STATE.FAIL, payload: msg });
			dispatch({ type: SET_AUTH_ERROR, payload: msg });
			dispatch(logout());
		}

		return null;
	};
}

export function login({ type, onBeforeComplete }: LoginParams) {
	return async (dispatch: Dispatch) => {
		dispatch({ type: LOGIN.START, payload: type });
		dispatch({ type: SET_AUTH_ERROR, payload: '' });

		try {
			switch (type) {
				case 'facebook': {
					const provider = new FacebookAuthProvider();
					if (isMatching(MOBILE)) {
						await signInWithRedirect(provider);
					} else {
						await signInWithPopup(provider);
					}
					break;
				}

				case 'google': {
					const provider = new GoogleAuthProvider();
					if (isMatching(MOBILE)) {
						await signInWithRedirect(provider);
					} else {
						await signInWithPopup(provider);
					}
					break;
				}

				case 'anonymous': {
					const response = await api.getCustomToken();
					await signInWithCustomToken(response.data);
					break;
				}

				default:
					break;
			}

			const user = firebaseAuth.currentUser;
			ApiWorker.token = await user.getIdToken(true);

			const userData = await api.authUser();

			if (userData.success && userData.data) {
				if (onBeforeComplete) {
					await onBeforeComplete();
				}

				dispatch<UserData>({
					type: LOGIN.SUCCESS,
					payload: {
						...parseUserData(user),
						...userData.data,
					},
				});
				return user;
			}

			dispatch(logout());

			throw new Error(userData.error);
		} catch (e) {
			const msg: string = e.message;

			dispatch({ type: LOGIN.FAIL, payload: msg });
			dispatch({ type: SET_AUTH_ERROR, payload: msg });
		}

		return null;
	};
}

export function setAuthError(msg: string) {
	return { type: SET_AUTH_ERROR, payload: msg };
}

export function setAuthBusyState(value: string) {
	return { type: SET_AUTH_BUSY_STATE, payload: value };
}

export function logout() {
	return async (dispatch: Dispatch) => {
		dispatch({ type: LOGOUT.START });
		firebaseAuth
			.signOut()
			.then(() => {
				localStorage.setItem('chart', '');
				dispatch({ type: LOGOUT.SUCCESS });
			})
			.catch(() => {
				dispatch({ type: LOGOUT.FAIL });
			});
	};
}
