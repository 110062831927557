// @flow
import _ from 'lodash';
import { connect } from 'react-redux';
import * as actions from 'client/constants/actions';

import Intro from './Intro';

const mapState = (state: ClientStore) => ({
	leads: _.get(state, 'leads'),
});
const mapDispatch = dispatch => ({
	setJsonLeads: leads => dispatch({ type: actions.ADD_JSON_LEADS, payload: leads }),
});

export default connect(mapState, mapDispatch)(Intro);
