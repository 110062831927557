/* eslint-disable max-len */
// @flow

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const OG_IMAGE_PARAMS = '?w=200&h=200&fit=crop';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
	INSTAGRAM: 'Instagram',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	LEADS: 'leads',
};

export const PLACEHOLDERS_TEXTS = {
	fullName: 'שם מלא',
	phone: 'טלפון',
	email: 'כתובת מייל',
	age: 'גיל',
	message: '<span>[עד 500 תווים]</span>',
};

export const ERRORS_TEXTS = {
	fullName: 'יש למלא שם מלא',
	age: 'יש למלא גיל',
	email: 'יש למלא כתובת מייל',
	emailNotValid: 'יש למלא כתובת מייל תקינה',
	phoneNumber: 'יש למלא מספר טלפון',
	phoneNumberNotValid: 'יש למלא מספר טלפון תקין',
	recaptchaError: `<span>עלייך לאשר שאינך רובוט</span>`,
	message: 'יש לתאר את הסיפור שלכם',
	messageMaxLength: 'יש למלא את הסיפור שלכם עד 500 מילים',
	checkbox: 'יש לאשר את התקנון, מדיניות הפרטיות ותנאי השימוש',
};

export const texts = {
	introTitle:
		'הגעתם לטופ, שברתם שיא אישי, הצלחתם להתמודד עם אתגר לא פשוט ועל אף הקשיים התמדתם והמשכתם בנחישות אל עבר ההצלחה? זה הסיפור שאנחנו רוצים לשמוע.',
};

export const ALLOWED_QUERY_PARAMS = [
	'p',
	'externalurl',
	'utm_source',
	'utm',
	'utm_medium',
	'utm_campaign',
	'utm_term',
	'utm_content',
	'ref',
];
