import React from 'react';

export function useIntersectionObserver(ref, callback, options = {}) {
	React.useEffect(() => {
		const element = ref.current;
		let observer = null;

		if (element) {
			observer = new IntersectionObserver(
				([entry]) => {
					if (entry.isIntersecting && callback) {
						callback(entry);
					}
				},
				{
					threshold: 1.0,
					rootMargin: '0px',
					...options,
				}
			);

			observer.observe(element);
		}

		return () => {
			if (observer) observer.disconnect();
		};
	}, [ref, callback, options]);
}
