// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';

import { withRouter } from 'react-router';
// import { seoTitle, seoDescription, shareTitle, shareDescription, shareImage, shareUrl } from 'common/constants/seo';
// import faviconImg from 'assets/favicon.png';

import { detectIE } from 'common/utils/detect-ie';
import { ROOT_ROUTE, ITEM_ROUTE } from 'client/constants';
import Intro from 'client/components/pages/Intro';
import HelmetComponent from 'client/components/common/HelmetComponent';
import ItemPopUp from 'client/components/common/ItemPopUp';

import * as css from './App.scss';
import './rc-slider.scss';

type Props = {|
	history: RouterHistory,
	location: RouterLocation,
	leads: Array<LeadInfo>,
	isBusy?: boolean,
|};

class App extends React.PureComponent<Props> {
	static defaultProps = {
		isBusy: true,
	};

	async componentDidMount() {
		const isIE = detectIE();

		// tracker.init('UA-142761482-39');

		if (!this.props.isBusy) {
			this.removeRootPreloader();
		}

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');
		}
	}

	async componentDidUpdate(prevProps: Props) {
		if (!this.props.isBusy && prevProps.isBusy) {
			this.removeRootPreloader();
		}
	}

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 2500);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	render() {
		return (
			<div id="app" className={css.app}>
				{/* {this.renderHelmet()} */}
				<HelmetComponent leads={this.props.leads} />
				<div className={css.content}>
					<Switch>
						<Route exact path={ROOT_ROUTE}>
							{props => <Intro {...props} in={!!props.match} history={props.history} />}
						</Route>
						<Route exact path={ITEM_ROUTE}>
							{props => <ItemPopUp {...props} in={!!props.match} match={props.match} />}
						</Route>
					</Switch>
				</div>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	isBusy: false,
	leads: state.leads.list,
});

const mapDispatch = {};

export default withRouter(connect(mapState, mapDispatch)(App));
