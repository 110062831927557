/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow

import React, { useMemo, useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { APPROVED } from 'admin/constants';

import { sleep } from 'common/utils';
import { CAN_USE_DOM } from 'common/constants';
import { dataLayerEvents } from 'common/utils/gtag';

import { URLParams } from 'client/utils';
import { ROOT_ROUTE } from 'client/constants/routes';
import { useIntersectionObserver } from 'client/hooks/intersection-observer';

import CreateLeadPopUp from 'client/components/common/CreateLeadPopUp';
import FullWidthItem from 'client/components/common/FullWidthItem';
import { Responsive } from 'client/components/common';
import Search from 'client/components/common/Search';
import Item from 'client/components/common/Item';

import scrollTopArrow from 'assets/client/images/firstScreen/scrollTopArrow.png';
import leftArrow from 'assets/client/images/firstScreen/left-arrow.png';

import css from './FirstScreen.scss';

type Props = {
	leads: Leads,
};

const Loader = () => (
	<div className={css.ldsRing}>
		<div />
		<div />
		<div />
		<div />
	</div>
);

const smallTextDesk = `יום הזיכרון לחללי מערכות ישראל <br /> ונפגעי פעולות האיבה תשפ"ג 2023`;
const smallTextMob = `יום הזיכרון לחללי מערכות ישראל <br /> ונפגעי פעולות האיבה תשפ"ג 2023`;

const FirstScreen = (props: Props) => {
	const { leads } = props;
	const history = useHistory();

	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const isMobile = Responsive.isMatching(Responsive.MOBILE);

	const [activeCreateLeadPopUp, setActiveCreateLeadPopUp] = useState(false);
	const [isFakeLoading, setIsFakeLoading] = useState(false);
	// Button show
	const [isShowScrollTopButton, setIsShowScrollTopButton] = useState(false);
	// when use search
	const [filteredItems, setFilteredItems] = useState([]);
	// active index for slider in ItemPopUp
	const [isActiveAnimationActive, setIsAnimationActive] = useState(false);
	// Is search page or no
	const [isSearch, setIsSearch] = useState(false);
	// If don't have results
	const [isNoResults, setIsNoResults] = useState(false);
	// Loader
	const [isLoading, setIsLoading] = useState(false);

	const leadsData = useMemo(() => ({ chunkedItems: _.chunk(leads.list, 30), items: leads.list }), [leads]);
	const [loadedItems, setLoadedItems] = useState(leadsData.chunkedItems[0]);

	const loadMoreTriggerRef = useRef(null);
	const pageRef = useRef(0);

	const onLoadMoreTriggerIntersection = async () => {
		const nextPage = pageRef.current + 1;
		const nextPortion = leadsData.chunkedItems[nextPage];

		if (!nextPortion || isFakeLoading) return;

		setIsFakeLoading(true);
		pageRef.current = nextPage;

		setLoadedItems(currentItems => [...currentItems, ...nextPortion]);
		await sleep(500);
		pageRef.current = nextPage;
		setIsFakeLoading(false);
	};

	useEffect(() => {
		// Show/Hide go to top button
		if (CAN_USE_DOM && isDesktop) {
			const handleScroll = () => {
				const currentScrollY = window.scrollY;
				if (currentScrollY > 200) {
					setIsShowScrollTopButton(true);
				} else {
					setIsShowScrollTopButton(false);
				}
			};

			window.addEventListener('scroll', handleScroll, { passive: true });

			return () => window.removeEventListener('scroll', handleScroll);
		}
	}, []);

	const scrollToTop = () => {
		if (CAN_USE_DOM) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	// Red button click for a show CreateLeadPopUp
	const onButtonClick = () => {
		setIsAnimationActive(true);

		setTimeout(() => {
			setIsAnimationActive(false);
		}, 400);
		setTimeout(() => {
			setActiveCreateLeadPopUp(true);
		}, 200);
		dataLayerEvents.onCreateLeadClick();
	};

	const onItemClick = (index: number, id) => {
		// history.push(ITEM_ROUTE.replace(':id', id));
		const search = { p: id };

		history.replace({
			pathname: ROOT_ROUTE,
			search: `?${URLParams.stringify(search)}`,
		});
	};

	const onBackButtonClick = () => {
		setIsSearch(false);
		setFilteredItems([]);
		dataLayerEvents.onBackClick();
		setIsNoResults(false);
	};

	useIntersectionObserver(loadMoreTriggerRef, onLoadMoreTriggerIntersection, { rootMargin: '0px', threshold: 1.0 });

	const renderInfinityScroll = () => {
		if (isSearch || isLoading) {
			return null;
		}

		return (
			<>
				<div className={css.items}>
					{_.map(loadedItems, (item, index: number) => (
						<div className={css.itemWrapper} onClick={() => onItemClick(index, item.id)} key={item.id}>
							<Item item={item} key={item.id} />
						</div>
					))}
				</div>

				<div ref={loadMoreTriggerRef} />

				{isFakeLoading && (
					<div className={css.itemsLoader}>
						<Loader />
					</div>
				)}
			</>
		);
	};

	return (
		<div>
			<div className={css.content}>
				<p
					className={css.smallText}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: isMobile ? smallTextMob : smallTextDesk }}
				></p>
				<h1 className={css.title}>החלל שלי</h1>
				<div
					className={cn(css.redButton, isActiveAnimationActive && css.redButtonAnimation)}
					onClick={onButtonClick}
				>
					הדליקו נר זיכרון
					<img
						src={leftArrow}
						alt="left arrow"
						className={cn(css.leftArrow, isActiveAnimationActive && css.leftArrowActive)}
					/>
				</div>
				<div className={css.searchWrapper}>
					<Search
						leads={leadsData.items}
						setIsSearch={setIsSearch}
						setFilteredItems={setFilteredItems}
						setIsLoading={setIsLoading}
						setIsNoResults={setIsNoResults}
						isLoading={isLoading}
						isSearch={isSearch}
					/>
					<button
						type="button"
						onClick={() => onBackButtonClick()}
						style={{ display: isSearch ? 'flex' : 'none' }}
						className={cn(css.backButton, isSearch && css.visible)}
					>
						<p>חזרה לראשי </p>
						<img
							src={leftArrow}
							alt="left arrow"
							className={cn(
								css.leftArrow,
								css.leftArrowSmall,
								isActiveAnimationActive && css.leftArrowActive
							)}
						/>
					</button>
				</div>
				<div className={css.itemsWrapper}>
					{isLoading && <Loader />}
					{renderInfinityScroll()}
					<div className={cn(css.fullWidthItems, isSearch && !isLoading && css.visible)}>
						{_.map(filteredItems, item => {
							return item.leadStatus === APPROVED && <FullWidthItem item={item} key={item.id} />;
						})}
					</div>
					{isNoResults && <div className={css.noResults}>לא נמצאו תוצאות</div>}

					<CreateLeadPopUp
						setActiveCreateLeadPopUp={setActiveCreateLeadPopUp}
						activeCreateLeadPopUp={activeCreateLeadPopUp}
					/>
					{isDesktop && (
						<button
							className={cn(css.scrollTopButton, isShowScrollTopButton && css.visible)}
							type="button"
							onClick={scrollToTop}
						>
							<img src={scrollTopArrow} className={css.scrollTopArrow} alt="" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default FirstScreen;
