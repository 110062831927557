/* eslint-disable max-len */
// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import * as React from 'react';
import cn from 'classnames';
// import { randomBytes } from 'crypto';

import LeadCreatedPopUp from 'client/components/common/LeadCreatedPopUp';

import { LEAD_CONSTANTS } from 'common/constants';

import { COLLECTIONS } from 'client/constants';
import { Form, TextField, MediaField, MultipleMediaField, Responsive } from 'client/components/common';
import ReactSelect from 'client/components/common/Form/Select';
import { createItem, updateRealTimeDataBase } from 'client/actions';
import { initialize } from 'redux-form';
import { dataLayerEvents } from 'common/utils/gtag';
import * as css from './ContentForm.scss';

type FormData = {
	nameOfTheCandle?: string,
	birthYear?: {
		value: string,
		label: string,
	},
	deathYear?: {
		value: string,
		label: string,
	},
	freeText?: string,
	mainPicture?: FileURL,
	profilePicture?: FileURL,
	senderName?: string,
};

type Props = {
	values: FormData,
	errors: FormData,
	createItem: typeof createItem,
	// updateRealTimeDataBase: typeof updateRealTimeDataBase,
	setActiveCreateLeadPopUp: any,
};

type State = {
	isShowSenededFormPopup: boolean,
	isLoading: boolean,
	isShowError: boolean,
};

// const maxWordLength = (value: string, maxWords: number) => {
// 	if (value === undefined) return maxWords + 1 < 0;

// 	return value.toString().split(' ').length > maxWords + 1;
// };

const FORM_CONFIG = {
	form: 'userInfo',
};

class ContentForm extends React.PureComponent<Props, State> {
	static defaultProps = {
		// className: '',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isShowSenededFormPopup: false,
			isLoading: false,
			isShowError: false,
		};
	}

	componentDidMount() {
		const recaptchaBadge = document.querySelector('.grecaptcha-badge');

		if (recaptchaBadge) {
			recaptchaBadge.style.display = 'block';
			recaptchaBadge.style.visibility = 'visible';
		}
	}

	componentWillUnmount() {
		const recaptchaBadge = document.querySelector('.grecaptcha-badge');

		if (recaptchaBadge) {
			recaptchaBadge.style.display = 'none';
			recaptchaBadge.style.visibility = 'hidden';
		}
	}

	initFormValues = async () => {};

	onClickCloseCreatedPopUp = () => {
		this.setState({ isShowSenededFormPopup: false });
	};

	onFormSubmit = async (values: FormData) => {
		this.setState({
			// isUserNotified: false,
			isLoading: true,
		});

		if (!values.nameOfTheCandle) {
			this.setState({ isShowError: true, isLoading: false });
		} else {
			const leadResult = await this.props.createItem<any>({
				collection: COLLECTIONS.LEADS,
				data: {
					...values,
					birthYear: values.birthYear?.value ? values.birthYear.value : '',
					deathYear: values.deathYear?.value ? values.deathYear.value : '',
				},
			});

			if (leadResult && leadResult.lead === LEAD_CONSTANTS.CREATED) {
				this.setState({
					isShowSenededFormPopup: true,
					isLoading: false,
				});

				dataLayerEvents.onFormSended();
			} else {
				this.setState({
					isLoading: false,
				});
			}
		}
	};

	getYears = (a: number, b: number) => {
		const yearsArr: any = [];

		for (let i = 0; i <= b - a; i += 1) {
			const year = (a + i).toString();

			yearsArr.push({ value: year, label: year });
		}
		// yearsArr.reverse();
		return yearsArr;
	};

	render() {
		const { isLoading, isShowSenededFormPopup, isShowError } = this.state;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		const placeholderNameOfTheCandle = 'שם הנופל <span>(שדה חובה)</span>';

		return (
			<div className={css.formWrapper}>
				<Form className={css.form} config={FORM_CONFIG} onSubmit={this.onFormSubmit}>
					<div className={css.formInner} style={{ display: isShowSenededFormPopup && 'none' }}>
						<div className={css.row}>
							<div
								className={css.fieldWrapper}
								onClick={() => {
									this.setState({ isShowError: false });
								}}
							>
								<TextField
									name="nameOfTheCandle"
									placeholder={isMobile && isShowError ? '' : placeholderNameOfTheCandle}
									className={css.name}
								/>
								{isShowError && <p className={css.error}>יש להזין שם מלא</p>}
							</div>

							<ReactSelect
								name="birthYear"
								placeholder="שנת לידה"
								className={css.select}
								options={this.getYears(1890, 2022)}
							/>

							<ReactSelect
								name="deathYear"
								placeholder="שנת פטירה"
								className={css.select}
								options={this.getYears(1890, 2022)}
							/>
						</div>
						<div className={css.row}>
							<TextField
								name="freeText"
								isTextarea
								placeholder="מילים לזיכרון"
								className={css.textArea}
							/>
						</div>
						<div className={css.row}>
							<MediaField
								name="mainPicture"
								placeholder="צרפו תמונת פרופיל עד 5MB"
								className={cn(css.mediaField, css.mainPicture)}
								sizeLimit={5 * 1024 * 1024}
								fileType="IMAGE_FILE_TYPE"
							/>
							<MultipleMediaField
								name="profilePictures"
								placeholder="צרפו עד 4 תמונות, עד 5MB"
								sizeLimit={5 * 1024 * 1024}
								className={cn(css.mediaField, css.profilePictures)}
							/>
							<TextField name="senderName" className={css.senderName} placeholder="שם הכותב" />
						</div>
						<button type="submit" className={css.submitButton} disabled={isLoading}>
							{!isLoading ? (
								'שליחה'
							) : (
								<div className={css.ldsRipple}>
									<div></div>
									<div></div>
								</div>
							)}
						</button>
						<div className={css.bottomText}>
							אין להעלות תמונות ומידע הסותרים את{' '}
							<a
								href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
								target="_blank"
								rel="noreferrer"
							>
								התקנון
							</a>
						</div>
					</div>
				</Form>

				<LeadCreatedPopUp
					setActiveLeadCreatedPopUp={() => this.onClickCloseCreatedPopUp()}
					setActiveCreateLeadPopUp={this.props.setActiveCreateLeadPopUp}
					activeLeadCreatedPopUp={this.state.isShowSenededFormPopup}
				/>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => {
	const values = 'form.userInfo.values';
	const errors = 'form.userInfo.submitErrors';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
	};
};

const mapDispatch = {
	initializeForm: initialize,
	createItem,
	updateRealTimeDataBase,
};

export default connect(mapState, mapDispatch)(ContentForm);
